button, .btn {
    border: none;
    background-color: #2A913E;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
    padding: 1px 35px;
    border-radius: 4px;
    line-height: 22px;
    text-decoration: none;
}
