.add-form {
    width: 800px;
    margin: 50px auto;
}

.add-form label {
    display: block;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 25px;
}

.add-form p {
    margin-top: 20px;
}

.add-form input, textarea {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
}

.add-form label small {
    font-weight: normal;
}

.add-form button {
    padding: 15px;
    width: 100%;
    transition:  .2s;
}
.add-form button:hover {
    background-color: #186429;
    cursor: pointer;
}
